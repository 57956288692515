@import-normalize;

/* red-hat-display-regular - latin */
@font-face {
  font-family: 'RedHatDisplay';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/red-hat-display-v4-latin-regular.eot'); /* IE9 Compatible Modes */
  src: local(''),
    url('./assets/fonts/red-hat-display-v4-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/red-hat-display-v4-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/red-hat-display-v4-latin-regular.woff') format('woff'),
    /* Modern Browsers */
      url('./assets/fonts/red-hat-display-v4-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/red-hat-display-v4-latin-regular.svg#RedHatDisplay')
      format('svg'); /* Legacy iOS */
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  overflow: hidden;
}

#root {
  height: 100vh;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
}

ul {
  list-style-position: inside;
}

.gen-ai-markdown {
  text-align: justify;
  ol {
    padding-left: 20px;

    ul {
      ul {
        padding-left: 20px;
      }
    }
  }

  ul {
    padding-left: 20px;
  }

  li {
    text-align: left;
  }
}

.relative {
  position: relative;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
